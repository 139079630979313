<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 28 29" fill="none">
    <path
        d="M7 2.77734L3.5 7.44401V23.7773C3.5 24.3962 3.74583 24.9897 4.18342 25.4273C4.621 25.8648 5.21449 26.1107 5.83333 26.1107H22.1667C22.7855 26.1107 23.379 25.8648 23.8166 25.4273C24.2542 24.9897 24.5 24.3962 24.5 23.7773V7.44401L21 2.77734H7Z"
        :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.5 7.44336H24.5" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M18.6693 12.1094C18.6693 13.3471 18.1776 14.534 17.3024 15.4092C16.4273 16.2844 15.2403 16.776 14.0026 16.776C12.7649 16.776 11.5779 16.2844 10.7028 15.4092C9.8276 14.534 9.33594 13.3471 9.33594 12.1094"
        :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CartIcon',
  props: {
    stroke: {
      type: String,
      default: '#18181F'
    }
  }
}
</script>
